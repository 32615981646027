@font-face {
  font-display: swap;
  font-family: "icomoon";
  font-style: normal;
  font-weight: normal;
  src: url("../../fonts/icomoon.woff2?v4dq67") format("woff2"),
  url("../../fonts/icomoon.svg?v4dq67#icomoon") format("svg");
}

[class^="icon-"],
[class*=" icon-"] {
  &::before {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "icomoon" !important;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    -moz-osx-font-smoothing: grayscale;
    speak: none;
    text-transform: none;
  }
}

.icon-triangle-down:before {
  content: "\e967";
}

.icon-triangle-up:before {
  content: "\e966";
}

.icon-bed-frame:before {
  content: "\e959";
}

.icon-base-frame:before {
  content: "\e961";
}

.icon-pillow-covers:before {
  content: "\e95e";
}

.icon-check-circle:before {
  content: "\e964";
}

.icon-comforter:before {
  content: "\e95b";
}

.icon-undersheet:before {
  content: "\e95a";
}

.icon-edit:before {
  content: "\e95c";
}

.icon-close-circle:before {
  content: "\e95d";
}

.icon-double-bed:before {
  content: "\e95f";
}

.icon-pillows:before {
  content: "\e960";
}

.icon-trash:before {
  content: "\e962";
}

.icon-save:before {
  content: "\e963";
}

.icon-bedhead:before {
  content: "\e965";
}

.icon-delivery:before {
  content: "\e954";
}

.icon-100n:before {
  content: "\e955";
}

.icon-3x4x:before {
  content: "\e956";
}

.icon-france:before {
  content: "\e957";
}

.icon-trade-in:before {
  content: "\e958";
}

.icon-delivery:before {
  content: "\e954";
}

.icon-100n:before {
  content: "\e955";
}

.icon-3x4x:before {
  content: "\e956";
}

.icon-france:before {
  content: "\e957";
}

.icon-trade-in:before {
  content: "\e958";
}

.icon-instagram:before {
  content: "\e953";
}

.icon-calendar-rdv:before {
  content: "\e952";
}

.icon-phone-back:before {
  content: "\e951";
}

.icon-video::before {
  color: #666;
  content: "\e950";
}

.icon-timer::before {
  content: "\e94f";
}

.icon-megaphone::before {
  content: "\e94e";
}

.icon-heart-empty::before {
  color: #666;
  content: "\e94c";
}

.icon-heart-full::before {
  color: #cb1f43;
  content: "\e94d";
}

.icon-eye-alt::before {
  content: "\e94b";
}

.icon-radio-on::before {
  content: "\e94a";
}

.icon-radio-off::before {
  content: "\e949";
}

.icon-warning::before {
  content: "\e948";
}

.icon-bicycling::before {
  content: "\e942";
}

.icon-walking::before {
  content: "\e943";
}

.icon-driving::before {
  content: "\e944";
}

.icon-jobs::before {
  content: "\e945";
}

.icon-letter::before {
  content: "\e946";
}

.icon-origin::before {
  content: "\e947";
}

.icon-mc-form3::before {
  color: #666;
  content: "\e93e";
}

.icon-mc-calor1::before {
  color: #666;
  content: "\e93a";
}

.icon-mc-calor2::before {
  color: #666;
  content: "\e93b";
}

.icon-mc-form1::before {
  content: "\e93c";
}

.icon-mc-form2::before {
  content: "\e93d";
}

.icon-mc-free1::before {
  content: "\e93f";
}

.icon-mc-free3::before {
  content: "\e940";
}

.icon-mc-nsp::before {
  content: "\e941";
}

.icon-mc-size3::before {
  color: #45c654;
  content: "\e936";
}

.icon-mc-size4::before {
  color: #666;
  content: "\e937";
}

.icon-mc-size5::before {
  color: #666;
  content: "\e938";
}

.icon-mc-size1::before {
  color: #666;
  content: "\e939";
}

.icon-mc-step-4::before {
  content: "\e931";
}

.icon-mc-step-3::before {
  content: "\e932";
}

.icon-mc-step-2::before {
  content: "\e933";
}

.icon-mc-step-1::before {
  content: "\e934";
}

.icon-filter::before {
  content: "\e930";
}

.icon-check-thin::before {
  content: "\e92f";
}

.icon-best_price::before {
  color: #01025c;
  content: "\e92b";
}

.icon-best_quality::before {
  color: #01025c;
  content: "\e92c";
}

.icon-best_sell::before {
  color: #01025c;
  content: "\e92d";
}

.icon-best_quality_price::before {
  color: #01025c;
  content: "\e92e";
}

.icon-plus-circle::before {
  content: "\e928";
}

.icon-minus-circle::before {
  content: "\e929";
}

.icon-blank::before {
  content: "\e92a";
}

.icon-moon::before {
  content: "\e927";
}

.icon-zoom-in::before {
  content: "\e907";
}

.icon-zoom-out::before {
  content: "\e926";
}

.icon-stars::before {
  content: "\e925";
}

.icon-tw::before {
  content: "\e922";
}

.icon-fb::before {
  content: "\e923";
}

.icon-yt::before {
  content: "\e924";
}

.icon-phone::before {
  content: "\e912";
}

.icon-minus::before {
  content: "\e90a";
}

.icon-plus::before {
  content: "\e90b";
}

.icon-refresh::before {
  content: "\e900";
}

.icon-reinit::before {
  content: "\e901";
}

.icon-search::before {
  content: "\e902";
}

.icon-share::before {
  content: "\e903";
}

.icon-store::before {
  content: "\e904";
}

.icon-cross::before {
  content: "\e905";
}

.icon-close::before {
  content: "\e905";
}

.icon-mark::before {
  content: "\e91e";
}

.icon-mark2::before {
  content: "\e906";
}

.icon-sort::before {
  content: "\e908";
}

.icon-user::before {
  content: "\e909";
}

.icon-arrow-left::before {
  content: "\e90c";
}

.icon-arrow-up::before {
  content: "\e91f";
}

.icon-arrow-right::before {
  content: "\e920";
}

.icon-arrow-down::before {
  content: "\e921";
}

.icon-star::before {
  content: "\e90d";
}

.icon-back::before {
  content: "\e90e";
}

.icon-ticket::before {
  content: "\e90f";
}

.icon-cart::before {
  content: "\e910";
}

.icon-check::before {
  content: "\e911";
}

.icon-bus::before {
  content: "\e913";
}

.icon-download::before {
  content: "\e914";
}

.icon-caret-down::before {
  content: "\e915";
}

.icon-caret-up::before {
  content: "\e935";
}

.icon-eye::before {
  content: "\e916";
}

.icon-franchise::before {
  content: "\e917";
}

.icon-itineraire::before {
  content: "\e918";
}

.icon-home::before {
  content: "\e919";
}

.icon-user-thin::before {
  content: "\e91a";
}

.icon-hours::before {
  color: #666;
  content: "\e91b";
}

.icon-calendar::before {
  color: #666;
  content: "\e91c";
}

.icon-parking::before {
  color: #666;
  content: "\e91d";
}
