/* displayTop */
.header-search {
  position: relative;

  .search-input {
    background: $blue-dark;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 0;
    color: $white;
    font-family: $default-font;
    font-size: 13px;
    font-style: italic;
    height: 3.3rem;
    padding: 0 30px 0 15px;
    width: 100%;

    &:focus {
      border: 1px solid $white;
    }

    &::placeholder {
      color: $white;
    }
  }

  .search-submit {
    @include poa(0, 0, 0, null);
    background: transparent;
    border: 0;
    color: $white;
  }
}

/* Mobile search */
.mobile-search {
  background: $grey-lighter;
  border-bottom: 1px solid $grey-light;
  padding: 1px 0;

  .icon {
    color: $grey;
    padding: 15px 5px 15px 15px;
  }

  .mobile-search-input {
    background: $grey-lighter;
    border: 0;
    font-size: 16px;
    height: 48px;
    line-height: 48px;

    &::placeholder {
      color: $grey;
      font-size: 16px;
      font-style: italic;
    }
  }

  .mobile-search-button {
    @include animated(opacity);
    border: 0;
    opacity: 0;
    padding-right: 10px;

    .mobile-search-button-text {
      @include size(35px);
      background: $green;
      border: 0;
      border-radius: 50%;
      color: $white;
      display: block;
      font-size: 14px;
      line-height: 35px;
    }

    &.active {
      opacity: 1;
    }
  }
}

/* Liste résultats Ajax */
.ac_results {
  background: $white;
  border: 0;
  border-radius: 3px;
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.2);
  display: block;
  margin-top: 2px;
  padding: 5px 0;
  z-index: 6;

  li.ac_even,
  li.ac_odd {
    background: $white;
    color: lighten($grey, 11);
    font-family: $muli;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0;
    padding: 8px 15px 12px 15px;

    &.ac_over {
      background: $grey-lighter;
      color: $black;
    }
  }
}
