/* DisplayTop */
.header-user {
  position: relative;

  .submenu {
    @include animated(opacity);
    background: url("~img/fleche-cart.png") no-repeat 232px 20px;
    color: #666;
    height: auto;
    opacity: 0;
    padding: 26px 0 0 0;
    position: absolute;
    right: 0;
    text-transform: none;
    top: 28px;
    visibility: hidden;
    width: 280px;
    z-index: 2000;

    @media (min-width: $md-width) {
      display: block;
    }

    & > div {
      background: $white;

      ul {
        font-size: 16px;
        padding: 15px 10px 15px 15px;
      }

      li {
        display: block;
        font-size: 1.55rem;
        padding: 5px 5px 5px 0;
        text-align: left;

        a {
          color: $blue-dark;
          padding-left: 20px;
          position: relative;

          &::before {
            @include poa(50%, null, null, 0);
            content: "\e920";
            font-family: 'icomoon';
            font-size: 1rem;
            margin-top: -0.4rem;
            opacity: 0.5;
          }

          &:hover {
            text-decoration: underline;
          }
        }
      }

      > div {
        background: $blue;
        padding: 15px;

        a.logout {
          background: $blue-dark;
          border-radius: 0;
          color: $white;
          font-size: 16px;
          font-weight: 700;
          line-height: 36px;
          padding: 9px 15px;
          text-transform: none;
        }
      }
    }
  }

  &:hover {
    .submenu {
      opacity: 1;
      visibility: visible;
    }
  }
}
