@font-face {
  font-display: swap;
  font-family: "Muli";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/Muli-Regular.woff2") format("woff2");
}
@font-face {
  font-display: swap;
  font-family: "Muli";
  font-style: normal;
  font-weight: 600;
  src: url("../../fonts/Muli-SemiBold.woff2") format("woff2");
}
@font-face {
  font-display: swap;
  font-family: "Muli";
  font-style: normal;
  font-weight: 700;
  src: url("../../fonts/Muli-Bold.woff2") format("woff2");
}
@font-face {
  font-display: swap;
  font-family: "Muli";
  font-style: normal;
  font-weight: 900;
  src: url("../../fonts/Muli-Black.woff2") format("woff2");
}

.h1 {
  @include heading($black, 3.5, 4.5, 600, true);
}

.h2 {
  @include heading($grey, 2.5, 3, 600, true);
}

.h3 {
  @include heading($grey, 2, 2.5, 600, true);
}

.h4 {
  @include heading($grey, 1.8, 1, 600, true);
}

.h5 {
  @include heading($black, 1.6, 1, 600, false);
}

a {
  color: $grey;
  text-decoration: none;

  &:hover {
    color: $black;
    text-decoration: underline;
  }
}

.cms-style {
  a:not(.button) {
    color: $blue-dark;
    text-decoration: underline;
  }

  em {
    font-style: italic;
  }

  strong {
    font-weight: 700;
  }

  ul,
  ol {
    font-size: 1.4rem;
    line-height: 2rem;
    padding-left: 2rem;

    @media (min-width: $sm-width) {
      font-size: 1.6rem;
      line-height: 2.3rem;
    }
  }

  ul li {
    list-style: circle;
  }

  ol li {
    list-style: decimal;
  }

  p {
    font-size: 1.4rem;
    line-height: 2rem;
    margin-bottom: 1.5rem;

    @media (min-width: $sm-width) {
      font-size: 1.6rem;
      line-height: 2.3rem;
    }
  }

  h2 {
    @include heading(inherit, 1.8, 1, 900, false);

    @media (min-width: $sm-width) {
      font-size: 2.4rem;
      margin-bottom: 1.5rem;
    }
  }

  h3 {
    @include heading($grey-medium, 2, 1.5, true, false);
    margin-top: 3rem;
  }

  .btn-default {
    @extend .button;
  }
}

.text-small {
  font-size: 1.2rem;
}

.text-large {
  font-size: 1.8rem;
}

.text-xlarge {
  font-size: 2rem;
}

.text-italic {
  font-style: italic;
}
