/* Switch Menu */
.menu-action {
  @include animated(opacity);
  @include poa(50%, null, null, 15px);
  background: transparent;
  border: 0;
  color: $white;
  cursor: pointer;
  height: 14px;
  margin: -6px 0 0 0;
  opacity: 1;
  overflow: hidden;
  transition: all 0.5s ease-in-out 0s;
  width: 20px;

  span {
    @include animated(all, 0.25s, ease-in-out, 0s);
    @include poa(null, 0, null, 0);
    backface-visibility: hidden;
    background: $white;
    display: block;
    height: 2px;
    opacity: 1;

    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2) {
      top: 6px;
    }

    &:nth-child(3) {
      right: 3px;
      top: 12px;
    }
  }
}

.menu-open {
  .menu-action span:nth-child(1) {
    top: 10px;
    transform: rotate(135deg);
  }

  .menu-action span:nth-child(2) {
    left: -60px;
    opacity: 0;
  }

  .menu-action span:nth-child(3) {
    top: 10px;
    transform: rotate(-135deg);
  }
}

/* DisplayMenu */
.mainmenu {
  @include animated(background);
  @include pof(0, 0, 0, 0);
  background: rgba(0, 0, 0, 0);
  pointer-events: none;

  .header-phone {
    @include animated(left);
    @include pof(0, null, null, -101%);
    background: $blue-dark;
    display: block;
    font-size: 18px;
    margin-bottom: 0;
    padding: 10px 15px;
    text-align: left;
    width: 100%;

    @media (min-width: $xs-width) {
      width: 80%;
    }
    @media (min-width: $sm-width) {
      width: 50%;
    }
    @media (min-width: $md-width) {
      display: none;
    }

    .tel {
      color: inherit;
    }

    .hours {
      display: block;
      font-size: 10px;
      margin-top: 2px;
    }
  }

  .menu-content {
    @include animated(left);
    @include pof(50px, 0, 0, -101%);
    background: $grey-lighter;
    margin: 0;
    overflow-x: scroll;
    padding: 0;
    width: 100%;

    @media (min-width: $xs-width) {
      width: 80%;
    }
    @media (min-width: $sm-width) {
      width: 50%;
    }
  }

  .root-link,
  .back-ctn,
  .column {
    border-bottom: 1px solid $grey-light;
    color: $blue-dark;
    display: block;
    font-size: 16px;
    font-weight: 700;
    padding: 14px 50px 14px 30px;
    position: relative;

    &::before {
      @include animated(opacity);
      @include poa(16px, 30px);
      color: lighten($grey, 20);
      content: "\e920";
      font-family: "icomoon";
      font-size: 12px;
      font-weight: 400;
    }
  }

  .root-link::before {
    opacity: 0;
  }

  .has-submenu .root-link::before {
    opacity: 1;
  }

  .submenu {
    @include pof(50px, 0, 0, -101%);
    @include animated(left);
    background: $grey-lighter;
    margin: 0;
    padding-bottom: 80px;
    width: 100%;
    z-index: 5;

    @media (min-width: $xs-width) {
      width: 80%;
    }
    @media (min-width: $sm-width) {
      width: 50%;
    }

    .ctn {
      height: 100%;
      overflow-y: auto;
      padding: 0 1rem;
      width: 100%;

      @media (min-width: $md-width) {
        overflow: visible;
      }
    }
  }

  .root-link-ctn.open .submenu {
    left: 0;
  }

  .back-ctn {
    text-align: center;
    @media (min-width: $xs-width) {
      a{
        padding: 5px 40px;
      }
    }
    &::before {
      @include poa(18px, auto, auto, 15px);
      transform: rotate(180deg);
    }
  }

  .column {
    color: $grey;

    .menu-subtitle {
      @include animated(color);
      color: $grey;
      font-size: 14px;

      &:hover {
        color: $black;
      }
    }

    .child-links {
      display: none;
      padding: 7px 0;
    }

    .child-link {
      @include animated(color);
      color: $grey;
      cursor: pointer;
      display: block;
      font-size: 14px;
      font-weight: 400;
      padding: 7px 0;

      &.see-more {
        font-weight: 700;
      }

      &:hover {
        color: $black;
      }
    }

    &.open {
      &::before {
        content: "\e90a";
      }

      .child-links {
        display: block;
      }
    }
  }

  .see-all-ctn {
    @include poa(null, 0, 0, 0);
    background: $grey-lighter;
    padding: 17px 30px;

    .see-all-link,
    .see-all {
      @include animated(background);
      background: $grey;
      border-radius: 3px;
      color: $white;
      cursor: pointer;
      display: block;
      font-weight: 700;
      padding: 15px;
      text-align: center;
      text-transform: uppercase;

      &:hover {
        background: $black;
      }
    }
  }

  .cta-ctn {
    padding-top: 5px;
    text-align: center;
  }

  .aside-links {
    margin: 3rem 0;
    padding: 0 1rem;
    text-align: center;

    .root-link {
      border: 0.2rem solid $blue-dark;
      border-radius: 0.3rem;
      display: block;
      font-size: 1.2rem;
      height: 100%;
      padding: 1rem 0.5rem;

      &::before {
        display: none;
      }
    }

    .direct-link {
      color: $blue-dark;
      text-align: left;

      &.logout {
        color: $grey-medium;

        &::before {
          color: inherit;
          content: "\e905";
        }
      }
    }

    .icon {
      display: block;
      font-size: 2rem;
      margin-bottom: 0.5rem;
    }
  }

  .main-close-pane {
    @include poa(16px, 15px);
    color: $white;
    cursor: pointer;
    font-size: 13px;
  }

  &.open {
    background: rgba(0, 0, 0, 0.5);
    pointer-events: auto;

    .header-phone {
      left: 0;
    }

    .menu-content {
      left: 0;
    }
  }

  .cta {
    margin-top: 15px;
    padding: 0 15px;

    p {
      margin-bottom: 0;
      a{
        @media (max-width: $md-width) {
          display:block;
        }
        img {
          height: auto;
          max-width: 100%;
        }
      }
    }


  }
}

@media (min-width: $md-width) {
  .mainmenu {
    background: $white;
    margin-top: 15px;
    pointer-events: auto;
    position: relative;

    &.open {
      background: $grey-lighter;
    }

    &.hover {
      background: $grey-lighter;
    }

    .menu-content {
      background: transparent;
      height: auto;
      left: auto;
      margin: 0 auto;
      overflow-x: visible;
      padding: 0 15px;
      position: static;
      right: auto;
      width: $md-ctn-width;
    }

    .root-links {
      box-sizing: border-box;
      display: flex;
      flex: 0 1 auto;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .root-link {
      @include animated(all);
      border: 0;
      font-size: 14px;
      margin: 0 -6px;
      padding: 11px 15px;

      &::before {
        content: "";
      }

      span {
        display: inline-block;
        padding: 2px 6px 6px 6px;
      }

      &.exergue span {
        background: $yellow-light;
      }
    }

    .submenu {
      @include poa(46px, 0, auto, 0);
      @include animated(opacity);
      background: $white;
      box-shadow: 0 4rem 4rem rgba(50, 50, 50, 0.03);
      opacity: 0;
      visibility: hidden;
      width: auto;

      .ctn {
        width: $md-ctn-width;
      }
    }

    .back-ctn {
      display: none;
    }

    .column {
      border: 0;
      padding: 45px 15px 0 15px;
      width: 20%;

      .child-links {
        padding: 15px 0;
      }

      .child-link {
        padding: 7px 0;
      }

      &::before,
      &.open::before {
        content: "";
      }

      .child-links {
        display: block;
      }
    }

    .see-all-ctn {
      background: $white;
      padding-bottom: 0;
      text-align: center;

      .see-all-link,
      .see-all {
        display: inline-block;
        width: 280px;
      }
    }

    &.hover .root-link-ctn:hover {
      .submenu {
        opacity: 1;
        visibility: visible;
      }

      .root-link span {
        background: $blue;
        color: $white;
      }
    }
  }
}
@media (min-width: $lg-width) {
  .mainmenu {
    .menu-content {
      width: $lg-ctn-width;
    }

    .root-link {
      font-size: 15px;
    }

    .submenu {
      padding-bottom: 20px;

      .ctn {
        width: $lg-ctn-width;
      }
    }

    .see-all-ctn {
      position: static;
    }
  }
}
@media (min-width: $xl-width) {
  .mainmenu {
    .menu-content {
      width: $xl-ctn-width;
    }

    .root-link {
      font-size: 16px;
    }

    .submenu {
      .ctn {
        width: $xl-ctn-width;
      }
    }
  }
}
