.ctn-fluid,
.ctn {
  margin-left: auto;
  margin-right: auto;
}

.flex,
.col,
.line {
  box-sizing: border-box;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  justify-content: space-between;
}

.flex-md,
.col-md,
.line-md {
  @media (min-width: $md-width) {
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.line {
  flex-direction: row;
}

.line.reverse {
  flex-direction: row-reverse;
}

.col {
  flex-direction: column;
}

.col.reverse {
  flex-direction: column-reverse;
}

.wrap {
  flex-wrap: wrap;
}

.nowrap {
  flex-wrap: nowrap;
}

@mixin flex-styles($breakpoint, $data) {
  $width-ctn: map-get($data, container);
  $gutter: map-get($data, gutter);
  $gutter-ctn: map-get($data, gutter-ctn);

  .ctn-fluid,
  .ctn {
    padding-left: #{$gutter-ctn};
    padding-right: #{$gutter-ctn};
  }

  .ctn {
    position: relative;
    width: $width-ctn;
  }

  .line,
  .col {
    margin-left: -#{$gutter*0.5};
    margin-right: -#{$gutter*0.5};
  }

  .line-#{$breakpoint} {
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: -#{$gutter*0.5};
    margin-right: -#{$gutter*0.5};
  }

  .col-#{$breakpoint} {
    flex-direction: column;
    margin-left: -#{$gutter*0.5};
    margin-right: -#{$gutter*0.5};
  }

  @for $i from 1 through $grid-columns {
    .#{$breakpoint}#{$i},
    .offset-#{$breakpoint}#{$i} {
      @extend %flex-global-styles-#{$breakpoint};
    }

    .#{$breakpoint}#{$i} {
      flex-basis: #{$column-width * $i + "%"};
      max-width: #{$column-width * $i + "%"};
    }

    .offset-#{$breakpoint}#{$i} {
      margin-left: #{$column-width * $i + "%"};
    }
  }

  .#{$breakpoint} {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    padding-left: #{$gutter*0.5};
    padding-right: #{$gutter*0.5};
  }

  .start-#{$breakpoint} {
    justify-content: flex-start;
    text-align: start;
  }

  .center-#{$breakpoint} {
    justify-content: center;
    text-align: center;
  }

  .end-#{$breakpoint} {
    justify-content: flex-end;
    text-align: end;
  }

  .around-#{$breakpoint} {
    justify-content: space-around;
  }

  .between-#{$breakpoint} {
    justify-content: space-between;
  }

  .top-#{$breakpoint} {
    align-items: flex-start;
    align-self: start;
  }

  .middle-#{$breakpoint} {
    align-items: center;
    align-self: center;
  }

  .bottom-#{$breakpoint} {
    align-items: flex-end;
    align-self: end;
  }

  .first-#{$breakpoint} {
    order: -1;
  }

  .last-#{$breakpoint} {
    order: 1;
  }

  .hide-#{$breakpoint} {
    display: none;
  }

  .show-#{$breakpoint} {
    display: block;
  }

  %flex-global-styles-#{$breakpoint} {
    box-sizing: border-box;
    padding-left: #{$gutter*0.5};
    padding-right: #{$gutter*0.5};
  }

  .#{$breakpoint}-pad0 {
    padding-left: 0;
    padding-right: 0;
  }
}

@each $breakpoint, $data in $breakpoints {
  $min-width: map-get($data, min-width);

  @if ($min-width) {
    @media (min-width: $min-width) {
      @include flex-styles($breakpoint, $data);
    }
  } @else {
    @include flex-styles($breakpoint, $data);
  }
}

.nogrow {
  flex-basis: auto;
  flex-grow: 0;
}

@for $i from 1 through 5 {
  .grow#{$i} {
    flex-basis: auto;
    flex-grow: #{$i};
  }
}

.w-100 {
  width: 100%;
}
