/* Breakpoint */
@use "sass:math";
@use "sass:map";

$xs-width: 480px;
$sm-width: 767px;
$md-width: 992px;
$lg-width: 1200px;
$xl-width: 1300px;

$xs-ctn-width: 100%;
$sm-ctn-width: 750px;
$md-ctn-width: 970px;
$lg-ctn-width: 1170px;
$xl-ctn-width: 1290px;

$gutter-default: 3rem;
$gutter-ctn-default: $gutter-default*0.5;

$grid-breakpoints: (
  xxs: 0,
  // xs: 480px,
  sm: 767px,
  md: 992px,
  // lg: 1200px,
  // xl: 1300px
) !default;

/* Grid */
$grid-columns: 12;
$column-width: math.div(100, $grid-columns);
$breakpoints: (
  xxs: (
    container: $xs-ctn-width,
    min-width: false,
    gutter: 1.5rem,
    gutter-ctn: 1.5rem
  ),
  xs: (
    container: $xs-ctn-width,
    min-width: $xs-width,
    gutter: $gutter-default,
    gutter-ctn: $gutter-ctn-default
  ),
  sm: (
    container: $sm-ctn-width,
    min-width: $sm-width,
    gutter: $gutter-default,
    gutter-ctn: $gutter-ctn-default
  ),
  md: (
    container: $md-ctn-width,
    min-width: $md-width,
    gutter: $gutter-default,
    gutter-ctn: $gutter-ctn-default
  ),
  lg: (
    container: $lg-ctn-width,
    min-width: $lg-width,
    gutter: $gutter-default,
    gutter-ctn: $gutter-ctn-default
  ),
  xl: (
    container: $xl-ctn-width,
    min-width: $xl-width,
    gutter: $gutter-default,
    gutter-ctn: $gutter-ctn-default
  )
);

$spacer: 20px !default;
$spacers: (
  0: 0,
  1: (
    $spacer * 0.25,
  ),
  2: (
    $spacer * 0.5,
  ),
  3: (
    $spacer * 0.75,
  ),
  4: (
    $spacer * 1,
  ),
  5: (
    $spacer * 1.5,
  ),
  6: (
    $spacer * 2.5,
  ),
  7: (
    $spacer * 4,
  ),
  8: (
    $spacer * 6,
  ),
  9: (
    $spacer * 7.5,
  ),
);

/* Couleurs */
$white: #fff;
$black: #000;
$blue: #2a549c;
$blue-dark: #01025c;
$private-sales-bg: #fbfaf7;
$red: #cb1f43;
$red-dark: #aa1b3a;
$red-soldes: #e30613;
$green: #00cb40;
$green-light: #76e191;
$yellow: #ff0;
$yellow-light: #fafa7a;
$yellow-dark: #e8c501;
$orange: #e88143;
$grey: #494949;
$grey-medium: lighten($grey, 11);
$grey-light: #dadada;
$grey-lighter: #f4f4f4;
$color-ope30: #35a2db;
$oney-primary-color: #75c000;
$paypal-primary-color: #009cde;

// used for configurator mostly
$colors: (
  brand: (
    secondary: #0450f5,
    base: $blue-dark,
    medium: #404185,
    light: #8082ab,
  ),
  text: (
    base: #656565,
    highlight: #fe4d66,
    error: #fe4d66,
    success: #2cd461,
    warning: #f08749,
  ),
  background: (
    primary: #f1f8fe,
    success: #e1ffea,
    warning: #f7e5da,
    error: #ffe8eb,
  ),
  border: (
    base: #e1ebf4,
  )
);

@function color($color, $tone: "base") {
  @return map.get($colors, $color, $tone);
}

/* Radius */
$radius: 8px;

/* Fonts */
$muli: "Muli";
$default-font: $muli;
$default-fontsize: 1.6rem;
$default-fontweight: 400;

/* font weight */
$fw-regular: 400;
$fw-semibold: 600;
$fw-bold: 700;
$fw-black: 900;

/* Breakpoints */
$header-size-sm: 11.1rem;
$header-size-xxs: 10.1rem;

/* Header height */
$header-size-unstack: 19.8rem;
$header-size-unstack-md: 12.3rem;
$header-size-unstack-sm: 12.3rem;
$header-size-unstack-xxs: 9.7rem;
$header-size-stack: 13rem;

/* Custom input */
$heightCheckbox: 1.4rem;
