.prismic-topbanner {
  @include new-default-style;
  background: $white;
  color: $blue-dark;
  font-size: 1.1rem;
  font-weight: 700;
  height: 52px;

  @media (min-width: $md-width) {
    font-size: 1.2rem;
    height: 62px;
  }

  @media (min-width: $lg-width) {
    font-size: 1.6rem;
  }

  &__item {
    line-height: 2;
    max-height: 6.2rem;
    padding: 1rem 1.5rem;

    @media (min-width: $xs-width) {
      padding: 1.5rem;
    }

    p {
      margin-bottom: 0;
    }

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  &__picto {
    margin-right: 5px;
  }

  &__content {
    align-items: center;
    border-radius: 0.2rem;
    display: inline-flex;
    padding: 0.3rem 0.5rem;
  }

  &__desc {
    line-height: 2.6rem;
  }
}

@keyframes slideInUp {
  0% {
    transform: translate3d(0, 100%, 0);
    visibility: visible
  }

  to {
    transform: translateZ(0)
  }
}

.slideInUp {
  animation-name: slideInUp
}

@keyframes slideOutUp {
  0% {
    transform: translateZ(0)
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -100%, 0)
  }
}

.slideOutUp {
  animation-name: slideOutUp
}
