/* Skin des titres
/// @output Retourne les styles prédéfinis pour le titre
/// @group Test
/// @param {Hexa} $color
/// @param {Int} $font-size [$default-fontsize] - Taille du texte en rem
/// @param {Int} $margin-bottom [30] - Marge sous le titre
/// @param {Int} $bold [$default-fontweight] - Graisse de la typo
/// @param {Boolean} $uppercase [true] - La typo est-elle en majuscule ?
/// @example scss - Générer les styles pour .h1
///   .h1 {
///       @include heading($red, 1.4rem, 1.5, 900, false);
///   } */
@mixin heading(
  $color: $black,
  $font-size: $default-fontsize,
  $margin-bottom: 30,
  $bold: $default-fontweight,
  $uppercase: true
) {
  color: $color;
  display: block;
  font-size: #{$font-size}rem;
  font-weight: $bold;
  margin-bottom: #{$margin-bottom}rem;

  @if ($uppercase == true) {
    text-transform: uppercase;
  }
}

/* Timing des animations
/// @output Retourne les timing des animations
/// @group Test2
/// @param {Hexa} $prop - Propriété à animer
/// @param {Time} $time [0.3s] -Temps en seconde
/// @param {Int} $easing [ease] - Type de transition
/// @param {Time} $delay [0s] - Délai en seconde
/// @example scss - Configurer les animations pour .button
///   .button {
///       @include animated(opacity, 0.2s);
///   }
/// @todo Gérer les multi options de transition */
@mixin animated($prop: all, $time: 0.3s, $easing: ease, $delay: 0s) {
  transition: $prop $time $easing $delay;
}
@mixin poa($top: null, $right: null, $bottom: null, $left: null) {
  position: absolute;

  @include position($top, $right, $bottom, $left);
}
@mixin pof($top: null, $right: null, $bottom: null, $left: null) {
  position: fixed;

  @include position($top, $right, $bottom, $left);
}
@mixin position($top, $right, $bottom, $left) {
  @if ($bottom != null) {
    bottom: $bottom;
  }
  @if ($left != null) {
    left: $left;
  }
  @if ($right != null) {
    right: $right;
  }
  @if ($top != null) {
    top: $top;
  }
}
@mixin size($width, $height: $width) {
  height: $height;
  width: $width;
}
@mixin new-default-style() {
  font-family: $default-font;
  font-size: $default-fontsize;
  font-weight: $default-fontweight;
  line-height: 1;
  text-size-adjust: 100%;
}
@mixin link-style {
  background: transparent;
  border: none;
  color: $grey-medium;
  font-family: $muli;
  font-size: 1.2rem;
  padding: 0;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
@mixin link-style2 {
  @include link-style;
  color: $black;
  font-size: 1.6rem;
  font-weight: 700;
}
@mixin small-title {
  color: $black;
  font-family: $muli;
  font-size: 1.6rem;
  font-weight: 700;
}
@mixin medium-title {
  font-family: $muli;
  font-size: 2.4rem;
  font-weight: 900;
  line-height: 2.8rem;
  text-decoration: none;
}
@mixin align-middle {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
@mixin full-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@mixin horizontal-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
@mixin vertical-center {
  position: absolute;
  left: 50%;
  transform: translateY(-50%);
}
