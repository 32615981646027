html {
  font-family: $default-font;
  font-size: 10px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-size-adjust: 100%;
}

body {
  color: $grey-medium;
  font-size: 1.4rem;
}

/* Header */
.header-main {
  @include new-default-style;
  background: $blue-dark;
  padding: 1.3rem 0;
  position: relative;
  z-index: 5;

  & > .ctn {
    padding-left: 5.5rem;

    @media (min-width: $sm-width + 55px) {
      padding: 0 1.5rem;
    }
  }

  @media (min-width: $md-width) {
    padding: 1.5rem 0 0 0;
  }

  .header-side {
    @media (min-width: $lg-width) {
      max-width: 36rem;
    }
  }

  .header-phone {
    color: $white;
    display: none;
    font-size: 2.2rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    text-align: center;

    @media (min-width: $xl-width) {
      display: inline-block;
    }

    a {
      color: inherit;
    }

    .hours {
      display: block;
      font-size: 1.3rem;
      font-style: italic;

      @media (min-width: $xl-width) {
        display: inline-block;
      }
    }
  }

  .header-icon {
    color: $white;
    flex-grow: 0;
    padding: 0 1rem 0 0.5rem;

    &.header-search {
      order: 1;
    }

    &.header-user {
      order: 2;
    }

    &.header-cart {
      order: 3;
      position: relative;
    }

    @media (min-width: $xs-width) {
      padding: 0 1rem;
      text-align: center;
    }

    .header-icon-link {
      color: inherit;
      display: block;
      position: relative;
    }

    .header-label {
      font-size: 1.3rem;
      font-weight: 700;
      margin-top: 1rem;
    }
  }

  .header-awards {
    line-height: 0;
  }

  .header-awards img {
    max-height: 6.8rem;
  }

  .header-pin {
    @include animated(opacity);
    @include poa(0, 50%);
    @include size(0.6rem);
    background: $red;
    border-radius: 50%;
    margin-right: -0.3rem;
    opacity: 1;
    text-align: center;

    &.empty {
      opacity: 0;
    }
  }

  &.header-light {
    padding: 1rem 0;

    @media (min-width: $xs-width) {
      padding: 2rem 0;
    }

    > .ctn {
      padding-left: 1.5rem;
    }

    .header-phone {
      margin-bottom: 0;
    }
  }
}

.is-sticky {
  @media (min-width: $md-width) {
    padding-top: 0;
    transition: top 0.5s 0.3s;

    .hide-sticky {
      display: none;
    }

    .header-label {
      height: 0;
      opacity: 0;
    }
  }
}

/* Site content */
.site-content {
  position: relative;
  z-index: 2;
}

.header-light + .site-content {
  z-index: 6;
}

.footer-main {
  background: $grey-lighter;

  @media (min-width: $sm-width) {
    padding: 1.5rem 0;
  }
  @media (min-width: $lg-width) {
    padding: 2.5rem 0;
  }

  .accordion {
    border-bottom: 0.1rem solid $grey-light;

    @media (min-width: $sm-width) {
      border: 0;
    }

    .accordion-trigger {
      display: block;
      font-family: $muli;
      font-size: 1.4rem;
      font-weight: 700;
      padding: 1.5rem 6rem 1.5rem 1.5rem;
      position: relative;

      @media (min-width: $xs-width) {
        padding: 1.5rem 0;
      }
      @media (min-width: $lg-width) {
        font-size: 1.8rem;
      }

      &::before {
        @include poa(50%, 3rem);
        content: "\e921";
        font-family: "icomoon";
        font-size: 1rem;
        font-weight: 400;
        margin-top: -0.9rem;

        @media (min-width: $xs-width) {
          content: "";
          display: none;
        }
      }

      &.open::before {
        content: "\e90a";
      }
    }

    .accordion-content {
      display: none;

      @media (min-width: $xs-width) {
        display: block;
      }

      &.open {
        display: block;
      }
    }
  }
}

#gotop {
  background: #01025c url("~img/icon/backtotop.png") no-repeat center center;
  border: 2px solid #f4f4f4;
  border-radius: 5px;
  bottom: 70px;
  cursor: pointer;
  height: 40px;
  opacity: 0;
  position: fixed;
  right: 50px;
  transition: opacity 0.2s ease 0s;
  width: 40px;
  z-index: 50;

  &.active {
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }
}